<template>
  <div class="text-center reportCard">
    <v-img
      class="reportCard__dadaImage"
      alt="Learn Gujarati"
      src="../../assets/dada.jpg"
    >
    </v-img>
    <div class="reportCard__score">
      <v-progress-linear
        v-model="finalScore"
        color="green"
        dark
        height="25"
      ></v-progress-linear>
      <div class="mt-3 mb-3">
        <div class="float-left green--text font-weight-bold text-h5">
          {{ reportData.correctCount }}
        </div>
        <div class="float-right red--text font-weight-bold text-h5">
          {{ reportData.totalNumberOfQuestions - reportData.correctCount }}
        </div>
      </div>

      <div
        class="text-h4 text-md-h3 mt-7 font-weight-bold reportCard__scorePercent"
      >
        {{ `${$t('Score')}: ${finalScore}%` }}
      </div>
    </div>
  </div>
</template>
<script>
import { firebaseAnalytics } from '../../firebase'

export default {
  components: {},
  props: ['reportData'],
  data: () => ({}),
  computed: {
    finalScore() {
      return (
        (this.reportData.correctCount /
          this.reportData.totalNumberOfQuestions) *
        100
      ).toFixed(0)
    }
  },
  mounted() {
    firebaseAnalytics.logEvent('tutorial_complete', {
      level: this.reportData.level,
      score: this.finalScore,
      name: this.$route.name
    })
  }
}
</script>
<style lang="scss" scoped>
@import '../../styles/global.scss';

.reportCard {
  &__dadaImage {
    width: 75%;
    margin: auto;
    @include md-up {
      width: 80%;
    }
  }
  &__score {
    width: 85%;
    margin: auto;
    @include md-up {
      width: 90%;
    }
  }
  &__scorePercent {
    color: var(--v-primary-darken2);
  }
}
::v-deep .v-progress-linear {
  border-radius: 5px;
}
::v-deep .v-progress-linear__background {
  opacity: 1 !important;
}
::v-deep .v-progress-linear__background {
  background-color: #b90303 !important;
  border-color: #b90303 !important;
}
</style>
